import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { Fragment } from "react";
import { Trans, useTranslation } from "react-i18next";

import LanguageSelector from "../components/LanguageSelector";
import Logo, { LogoTypes } from "../components/Logo";
import { useUserService } from "../services/user";
import { shared } from "../utils/global/shared";
import * as styles from "./styles.module.scss";

const Landing = (): JSX.Element | null => {
    const { t } = useTranslation();
    const { user, login } = useUserService();

    if (user) {
        navigate("/dashboard/");
        return null;
    }

    return (
        <Fragment>
            <AppBar
                className={classNames(styles.header, styles.override)}
                elevation={0}
                position="static"
            >
                <Toolbar variant="dense">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <Grid item>
                            <Logo type={LogoTypes.SOMNILOG}></Logo>
                        </Grid>
                        <Grid item xs>
                            <LanguageSelector
                                fullWidth
                                selectClasses={classNames(
                                    styles.select,
                                    styles.override,
                                )}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Container maxWidth="xs">
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box p={2}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box my={3}>
                                <Logo width={100} type={LogoTypes.BRAND}></Logo>
                            </Box>
                        </Grid>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box mb={3} width={1}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    onClick={() => login()}
                                >
                                    {t("Login")}
                                </Button>
                            </Box>
                            <Button
                                color="secondary"
                                variant="contained"
                                fullWidth
                                onClick={() => navigate("/sign-up/")}
                            >
                                {t("Sign Up")}
                            </Button>
                        </Grid>
                    </Box>
                    <Grid item>
                        <Box py={4}>
                            <Container maxWidth="xs" fixed>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <Typography variant="body2">
                                            <Trans i18nKey="Footer">
                                                Contact us at
                                                <Link
                                                    href={`mailto:${shared.email}`}
                                                    underline="hover"
                                                >
                                                    {shared.email}
                                                </Link>
                                            </Trans>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
};

export default Landing;
